import React, {FunctionComponent, useCallback} from 'react'
import styled from "styled-components"
import {ConsolidatedDimension, BoxesWrapperWithResume, ConfDimensionFilterTypes, ConfFilter, FilterType, converterFilterToConfModel, LoadDictionaryEntriesWithoutEnvironment} from "@biron-data/react-bqconf"
import {useDimensionFilterConverterToConfModel} from "components/forms/chart/utils"
import {useMediaDesktop} from "@biron-data/react-hooks"

interface Props {
  filters: ConfDimensionFilterTypes[]
  availableDimensions: ConsolidatedDimension[]
  onFiltersChange: (filter: ConfDimensionFilterTypes, index: number) => void
  onFiltersDelete: (index: number) => void
  isFilterLineFullSize: boolean
  isEditable: boolean,
  toggleFilterLine: () => void
  onEdit?: (state: number) => void
  loadDictionaryEntries: LoadDictionaryEntriesWithoutEnvironment
}

const FilterLine: FunctionComponent<Props> = ({
                                                filters = [],
                                                availableDimensions,
                                                onFiltersChange,
                                                onFiltersDelete,
                                                isFilterLineFullSize,
                                                isEditable,
                                                toggleFilterLine,
                                                onEdit,
                                                loadDictionaryEntries
                                              }) => {
  const isMediaDesktop = useMediaDesktop()
  const filterConverter = useDimensionFilterConverterToConfModel(filters, availableDimensions)

  const handleChange = useCallback((confFilter: ConfFilter, index: number) => {
    if (confFilter.type === FilterType.dimension) {
      onFiltersChange(converterFilterToConfModel([confFilter], availableDimensions)[0], index)
    }
  }, [availableDimensions, onFiltersChange])

  return filters.length > 0 && isMediaDesktop
    ? <StyledFilterLine>
      <StyledBoxesWrapper {...{
        filters: filterConverter(),
        onFiltersChange: handleChange,
        onFiltersDelete,
        layout: 'line',
        isFilterLineFullSize,
        isEditable,
        toggleFilterLine,
        onEdit,
        loadDictionaryEntries
      }}/>
    </StyledFilterLine>
    : null
}

export default FilterLine;

const StyledBoxesWrapper = styled(BoxesWrapperWithResume)`
  background-color: var(--main-background-color);
`

const StyledFilterLine = styled.div`
  position: fixed;
  width: calc(100% - 240px);
  max-height: 80vh;
  z-index: 10;
  border-bottom: 1px solid var(--light-grey-2);
    line-height: var(--line-height);
   
   @media (max-width: 1200px) {
    width: 100%;
  }
`
