// @ts-nocheck   no check as this file should be deleted

import React, {memo, useMemo} from 'react'
import SimpleTooltip from 'components/charts/tooltip/SimpleTooltip'
import {formatAsText, formatValue, formatValueForRatio} from 'commons/format/formatter'
import {TooltipPopover} from "@biron-data/react-components"
import styled from "styled-components"
import {TableColumnWithProcess} from "types/charts"
import {MetricGrowthType} from "@biron-data/react-bqconf"

/* eslint-disable react/prop-types */
type Value = number | string

interface Props {
  column: TableColumnWithProcess
  rawValue: Value
  width: number
  total: Value
  printPercentage?: boolean
  color: string | undefined
}

export function computeGrowthColor(growthInvert: boolean, value: number): ColorType {
  return ((value < 0) === growthInvert) ? 'positive' : 'negative'
}

const getGrowthColor = (growth: boolean, type: MetricGrowthType, growthInvert: boolean, value: number): ColorType => {
  if (!growth || value === 0 || type === MetricGrowthType.VALUE) {
    return ''
  }
  return computeGrowthColor(growthInvert, value)
}

const TableChartDataCell = memo<Props>(function DataCell({color: originalColor, column, rawValue, width, total, printPercentage}) {
  const {format, nature, processValue} = column
  const value = processValue(rawValue)
  const formattedValue = nature === 'metric' ? formatValue(value, format) : formatAsText(value)
  const percentage = printPercentage && formatValueForRatio(value, format, total)

  const {growth, growthInvert, type} = format

  const color = useMemo(() => {
    switch (getGrowthColor(growth, type, growthInvert ?? false, value)) {
      case "negative":
        return "var(--negative)"
      case "positive":
        return "var(--positive)"
      default:
        return originalColor
    }
  }, [originalColor, growth, growthInvert, type, value])

  const style = {
    minWidth: width,
    width,
    maxWidth: width,
    color,
  }
  const cell = <td style={style}>
    <StyledContent style={style} translate="no">
      {formattedValue}
      {percentage && <PercentageSpan>({percentage})</PercentageSpan>}
    </StyledContent>
  </td>

  return value
    ? <TooltipPopover
      {...{
        size: "small",
        placement: "top",
        content: <SimpleTooltip {...{
          values: [value],
          total,
          format,
          copyEnabled: true,
        }}/>,
        mouseEnterDelay: 1,
        overlayClassName: 'biron-tooltip-sm',
      }}>
      {cell}
    </TooltipPopover>
    : cell
})

export default TableChartDataCell


const StyledContent = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const PercentageSpan = styled.span`
  margin-left: 5px;
  font-style: italic;
  color: var(--medium-grey);
`
