import {EffectiveConfMetric} from "../line/LineChart.types";
import {strToColor} from "@biron-data/react-bqconf";

export const getMetricColor = (
  metric: EffectiveConfMetric | undefined,
  value: number
): string | undefined => {
  if (metric?.extraConf?.color) {
    return strToColor.get(metric.extraConf.color) as string
  }
  if (metric?.extraConf?.interval) {
    return value < metric.extraConf.interval.step ? strToColor.get(metric.extraConf.interval.secondColor) as string : strToColor.get(metric.extraConf.interval.firstColor) as string
  }
  return undefined
}
