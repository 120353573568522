/* eslint-disable max-lines */
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {MetaModel, OptionListPagination} from "@biron-data/react-bqconf"
import {useSelector} from "react-redux"
import {getCurrentEnvironmentId, getCurrentMetaModel} from "redux/environment.selector"
import {getPermission} from "redux/appEnvironment.selector"
import {getPeriods} from "redux/period.selector"
import {getCurrentWorkspaceExpanded} from "redux/workspace.selector"
import HideableForm from "components/forms/Form.Hideable"
import {loadDictionaryEntries as loadDictionaryEntriesService} from "services/MetaModelService"
import {Category, Granularity, PeriodType, PeriodTypes, getResolvedFromPeriod, dayjs} from "@biron-data/period-resolver"
import useDispatch from "hooks/useDispatch"
import {Button, Col, Row, Table, TableColumnProps} from "antd"
import {IconContainer, PageTitle} from "@biron-data/react-components"
import {PencilIcon, PlusIcon} from "@heroicons/react/outline"
import styled from "styled-components"
import Language from "language"
import {ExpandedWorkspace} from "redux/models/workspace"
import FormPeriod from "components/forms/period/FormPeriod"
import {useNavigate} from "react-router-dom"
import {FormKeys, FormPeriodConfProps, FormType} from "components/forms/Form.types"
import IntervalSelector from "components/forms/selector/dashboardDate/intervalSelector/IntervalSelector"
import {PermissionsEnum} from "redux/models/appEnvironment"

const PeriodManager = () => {
  const getPermissionSelector = useSelector(getPermission)
  const userCanSetPowerUser = getPermissionSelector?.(PermissionsEnum.userCanManageAdvancedFeature)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const environmentId: number = useSelector(getCurrentEnvironmentId) as number
  const metaModel = useSelector(getCurrentMetaModel) as MetaModel
  const workspace = useSelector(getCurrentWorkspaceExpanded) as ExpandedWorkspace
  const periods = useSelector(getPeriods)
  const [editedData, setEditedData] = useState<PeriodTypes>()

  useEffect(() => {
    if (!userCanSetPowerUser) {
      navigate("/")
    }
  }, [navigate, userCanSetPowerUser])

  const defaultValues: PeriodTypes = useMemo(() => ({
    id: -1,
    type: PeriodType.fixed,
    start: dayjs().format(),
    end: dayjs().format(),
    category: Category.OTHER,
    code: undefined,
    nameFR: "",
    nameEN: "",
    position: periods.length * 10,
    canBeDeleted: true
  }), [periods.length])

  const [confEdit, setConfEdit] = useState(false)

  const formValue: PeriodTypes = useMemo(
    () => {
      return editedData ? ({
        ...editedData,
        title: editedData.code,
      }) : ({
        ...defaultValues,
      })
    },
    [defaultValues, editedData],
  )

  const handleConfCancel = useCallback(
    () => {
      setConfEdit(false)
      setEditedData(undefined)
    },
    [],
  )

  const handleDelete = useCallback((periodId: number) => {
    dispatch.periods.deletePeriod(periodId)
    handleConfCancel()
  }, [handleConfCancel, dispatch.periods])

  const handleConfSubmit = useCallback((newConf: PeriodTypes) => {
    handleConfCancel()
    return new Promise(() => {
      if (newConf.id === -1) {
        dispatch.periods.createPeriod({
          data: newConf,
        })
      } else {
        dispatch.periods.updatePeriod({
          data: newConf,
        })
      }
    })
  }, [handleConfCancel, dispatch.periods])

  const loadDictionaryEntries = useCallback(
    (dictionaryCode: string, search?: string, pagination?: OptionListPagination) => loadDictionaryEntriesService(environmentId, dictionaryCode, search, pagination),
    [environmentId],
  )

  const formType: FormType = useMemo(
    () => ({
      type: FormKeys.EXPORT_CONF,
    }),
    [],
  )

  const onConfEdit = useCallback(
    (periodData: PeriodTypes) => {
      setConfEdit(true)
      setEditedData(periodData)
    }, [],
  )

  const columns: TableColumnProps<PeriodTypes>[] = useMemo(() => ([
    {
      title: Language.get("period.informations"),
      width: '48%',
      sorter: (a, b) => {
        return b.position - a.position
      },
      render: function render(user, period) {
        return <PeriodInformations>
          <Col span={21}>
            <InformationContainer>
              <Row>
                <Col span={2}>
                  {period.position}
                </Col>
                <InformationsCol span={2} offset={1}>
                  <span>{Language.get(`period.category.${period.category}`)}</span>
                  <span>:</span>
                </InformationsCol>
                <Col span={19}>
                  <span>{Language.getTranslatedName(period)}</span>
                </Col>
              </Row>
            </InformationContainer>
          </Col>
          <Col span={1}>
            <Button onClick={() => onConfEdit(period)}>
              <IconContainer><PencilIcon/></IconContainer>
            </Button>
          </Col>
        </PeriodInformations>
      },
    },
    {
      title: Language.get("period.period"),
      width: '48%',
      render: function render(user, exportData) {
        const period = getResolvedFromPeriod(exportData)
        return <IntervalContainer>
          <IntervalSelector readOnly={true} value={{
            name: "mock",
            granularity: Granularity.QUARTER,
            period: exportData,
            start: period?.start ?? dayjs(),
            end: period?.end ?? dayjs(),
          }}/>
        </IntervalContainer>
      },
    },
  ]), [onConfEdit])

  const addExportButton = useMemo(() => <Button onClick={() => {
    setConfEdit(true)
  }} className="dashboard-add-widget-button" type="primary">
    <FlexDiv>
      <IconContainer margin={10}><PlusIcon/></IconContainer>
      {Language.get("period.add")}
    </FlexDiv>
  </Button>, [])

  return <div>
    <HideableForm<PeriodTypes, FormPeriodConfProps> {...{
      renderFormComponent: (props) => <FormPeriod {...props}/>,
      visible: confEdit,
      data: formValue,
      formType,
      metaModel,
      workspace,
      environmentId,
      loadDictionaryEntries,
      onConfirm: (newValue) => handleConfSubmit({
        ...formValue,
        ...newValue,
      }),
      onDelete: handleDelete,
      onCancel: handleConfCancel,
    }}/>
    <PageTitle>{Language.get("period.title")}</PageTitle>
    <Header>
      {addExportButton}
    </Header>
    <Table {...{
      size: 'small',
      bordered: true,
      pagination: false,
      columns,
      dataSource: periods.sort((a, b) => a.position - b.position),
      rowKey: 'id',
      scroll: {y: 650},
    }}/>
  </div>
}

export default PeriodManager

const Header = styled.div`
    display: flex;
    justify-content: end;
    margin: 10px;
`

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`

const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`
const InformationsCol = styled(Col)`
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
`

const PeriodInformations = styled(Row)`
    display: flex;
    justify-content: space-between;

    button {
        width: 40px;
        height: 40px;
    }
`

const IntervalContainer = styled.div`
    display: inline-block;

    .antcustom-calendar-range-picker-input {
        height: 30px;
    }

    &&& * {
        cursor: default;
    }
`