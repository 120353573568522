import React, {forwardRef, FunctionComponent, useMemo, useState} from 'react'
import FormModal, {ConsolidatedFormProps} from "components/forms/Form.Modal"
import {Form} from 'antd'
import {DimensionFiltersField, DimensionFiltersWrapper} from ".././confItems/DimensionFilters"
import {PeriodWrapper} from ".././confItems/Period"
import {useFields} from "@biron-data/react-hooks"
import {ConfFormDashboardDataTypes, FormDashboardConfProps} from "components/forms/Form.types"
import {SimplifiedDashboardFormProps} from "components/forms/chart/types"
import {FormInstance} from "antd/lib/form/hooks/useForm"
import Language from "language"
import {useDimensionFilterConverterToConfModel} from "components/forms/chart/utils"
import {useHandlePartialFormDataChange} from "components/forms/chart/hooks"
import {PeriodSelector, converterFilterToConfModel} from "@biron-data/react-bqconf"
import {useLoadPeriods} from "hooks/useLoadPeriods"
import {LockClosedIcon} from "@heroicons/react/outline"
import {WrapperItem, WrapperRoot} from "@biron-data/react-components"
import CheckboxSelector from "components/forms/export/selectors/CheckBoxSelector"
import {DashboardTypes} from "types/dashboards"

type FormDashboardComponent = SimplifiedDashboardFormProps & ConsolidatedFormProps<ConfFormDashboardDataTypes>

// eslint-disable-next-line react/display-name
const FormComponent = forwardRef<FormInstance, FormDashboardComponent>(({
                                                                          data,
                                                                          errors,
                                                                          availableDimensionsForFilters,
                                                                          loadDictionaryEntries,
                                                                          environmentId,
                                                                          onValuesChange,
                                                                        }, ref) => {
  const fields = useFields(data, errors)

  const loadPeriods = useLoadPeriods()

  return <Form ref={ref} fields={fields} name={'dashboardConf'} onValuesChange={onValuesChange}>
    <PeriodWrapper name={'period'} withDescription={true} itemColumnWidth={5}>
      <PeriodSelector {...{
        forTarget: false,
        withOverrideOption: false,
        withDateSlicerGranularity: false,
        name: 'period',
        span: 24,
        environmentId,
        loadPeriods,
      }} />
    </PeriodWrapper>
    <DimensionFiltersWrapper name={'filters'} itemColumnWidth={5}>
      <DimensionFiltersField {...{
        availableDimensions: availableDimensionsForFilters,
        loadDictionaryEntries,
        span: 24,
        environmentId,
        isEditable: true,
        GACategory: 'dashboards',
      }}/>
    </DimensionFiltersWrapper>
    {data.type === DashboardTypes.workspace && <>
      <WrapperRoot icon={LockClosedIcon}
                    label="configuration-label-protected"
                    description={"configuration-description-protected"} itemColumnWidth={5}>
        <WrapperItem name={"protected"}>
          <CheckboxSelector/>
        </WrapperItem>
      </WrapperRoot>
      <WrapperRoot icon={LockClosedIcon}
                   label="configuration-label-filtersLocked"
                   description={"configuration-description-filtersLocked"} itemColumnWidth={5}>
          <WrapperItem name={"filtersLocked"}>
              <CheckboxSelector/>
          </WrapperItem>
      </WrapperRoot>
    </>}
  </Form>
})


const FormDashboardConfCmp: FunctionComponent<FormDashboardConfProps> = ({
                                                                           data,
                                                                           metaModel,
                                                                           loadDictionaryEntries,
                                                                           onConfirm,
                                                                           onCancel,
                                                                           environmentId,
                                                                         }) => {
  const [formData, setData] = useState<ConfFormDashboardDataTypes>({
    ...data,
    filters: converterFilterToConfModel(data.filters),
  })

  const formProps: SimplifiedDashboardFormProps = useMemo(() => ({
      availableDimensionsForFilters: metaModel.listDimensions(),
      loadDictionaryEntries,
      environmentId,
    }),
    [metaModel, loadDictionaryEntries, environmentId],
  )

  const dimensionFilterConverter = useDimensionFilterConverterToConfModel(formData.filters, formProps.availableDimensionsForFilters)

  const consolidatedFormData: ConfFormDashboardDataTypes = useMemo(() => {
    return {
      ...formData,
      filters: dimensionFilterConverter(),
    }
  }, [dimensionFilterConverter, formData])

  const handlePartialFormDataChange = useHandlePartialFormDataChange(setData)

  return <FormModal<ConfFormDashboardDataTypes, SimplifiedDashboardFormProps> {...{
    defaultTitle: Language.get("navigation-tools-edit-dashboard"),
    width: 750,
    data: consolidatedFormData,
    setData: handlePartialFormDataChange,
    renderFormComponent: (props) => <FormComponent {...props}/>,
    formProps,
    onConfirm,
    onCancel,
  }} />
}

export default FormDashboardConfCmp
