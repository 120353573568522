/* eslint-disable max-lines */
import {WidgetTypes} from "commons/dashboard/dashboard.types"
import {MetaModel, ConsolidatedDimension, MetaModelView, SemanticType, dateOption, isFilterEmpty, DimensionOption,
  ConfDimensionFilterTypes,
  ConfFilter,
  ConfMetricFilterRangeDtoDetail,
  ConfMetricFilterScalarDtoDetail,
  ConfMetricFilterTypes,
  DimensionFilterDtoDetail,
  FilterDtoDetailTypes,
  FilterOperator,
  FilterType,
  MetricFilterDtoDetail,
  getMetricDef,
  ConfOrderBy,
  ConfSlicer,
  GenericChartTypes,
  GroupedOptions,
  MetricDtoDetail,
  MetricGrowthType,
  OrderBy,
  SlicerDtoDetailTypes,
  ViewWithMetrics,
  buildMetricLabel, converterFilterToConfModel, getMetricLabel} from "@biron-data/react-bqconf"
import * as _ from "lodash"
import {omit} from "lodash"
import {ChartGenericDtoDetail} from "types/charts"
import React, {useCallback} from "react"
import Language from "language"
import {Granularity} from "@biron-data/period-resolver"
import styled from "styled-components"
import {ConfLimit} from "types/widgets"
import {TargetExtendedConfModel} from "components/forms/chart/FormChartTargetCmp"
import {
  GenericAdditionalDetails,
  ConfigCache,
  DimensionCacheElement,
  ExportExtendedConfModel,
  ExtendedConfModel,
  GenericExtendedConfModel,
  ViewOption, TargetAdditionalDetails,
} from "components/forms/chart/types"
import {isEmpty, notEmpty, IconContainer} from "@biron-data/react-components"
import {ExportBasicDtoForm} from "components/forms/Form.types"
import {LightningBoltIcon} from "@heroicons/react/outline"
import {LanguageResolver, useLanguageResolver} from "@biron-data/react-contexts"

export const getColumnIndexByLabel = (sortLabel: string, groupedOptions: GroupedOptions) => Object.values(groupedOptions)
  .flatMap(option => option)
  .filter(Boolean)
  .findIndex(option => option?.label === sortLabel)

export const isSlicerSimple = (slicer: ConfSlicer, metaModel: MetaModel) => {
  switch (slicer.type) {
    case "dimension":
      return metaModel.getDimension(slicer.dimensionCode)?.asSimple
    default:
      return false
  }
}

export const isMetricRatio = (metric: MetricDtoDetail, metaModel: MetaModel) => Boolean(metaModel.getView(metric.viewCode)?.metrics?.find(({code}) => code === metric.metricCode)?.asRatio)

export const convertOrderByToCache = (
  id: string,
  index: number,
  orderBys?: ConfOrderBy[]) => {
  // sort.id is undefined when loading sorts from the server, so then we use the index which is up-to-date
  const appliedSortIndex = orderBys && orderBys.findIndex((sort) => sort.id === undefined ? sort.column === index : sort.id === id)

  const appliedSort = orderBys && appliedSortIndex !== undefined ? orderBys[appliedSortIndex] : undefined

  if (!appliedSort || appliedSort.isDefault) {
    return {
      orderByIndex: undefined,
      orderByAsc: undefined,
      orderByValue: undefined,
      isDefault: false,
    }
  }

  return {
    orderByOrder: appliedSortIndex,
    orderByIndex: index,
    orderByValue: appliedSort.value,
    orderByAsc: appliedSort.asc,
    isDefault: false,
  }
}

export const createSortFromCache = (cache: ConfigCache) => [...cache.slicers, ...cache.metrics]
  .filter(sort => sort.orderByOrder !== undefined && sort.orderByIndex !== undefined)
  .sort((a, b) => Number(a.orderByOrder) - Number(b.orderByOrder))
  .map(element => ({
    id: element.id,
    value: element.label,
    column: element.orderByIndex,
    asc: element.orderByAsc,
    isDefault: false,
  } as ConfOrderBy))

export const getDimensionLabel = (dimensionOptions: DimensionOption[], slicer: ConfSlicer) => {
  return slicer.type === "dimension" ? dimensionOptions.find(o => o.value === slicer.dimensionCode)?.label : dateOption.label
}

const formDataLimitToChartLimit = (limits: ConfLimit[]) => limits.map(limit => ({
  hideOthers: limit.hideOthers,
  limitSeries: limit.limitSeries,
}))

export const formDataToChartGeneric = <T extends GenericExtendedConfModel, >(formData: T, defaultTitle: string): Omit<T, "title" | "type"> & Pick<ExtendedConfModel, "extraConf" | "type" | "title"> => {
  return {
  ...formData,
    type: WidgetTypes.GENERIC,
    extraConf: {
      displayType: formData.displayType,
      limits: formData.limits ? formDataLimitToChartLimit(formData.limits) : [],
      displayLabels: formData.displayLabels,
      asPercentage: formData.asPercentage,
      ignoreMetrics0: formData.ignoreMetrics0,
      format: isEmpty(formData.format) ? undefined : formData.format,
      boundaries: formData.boundaries,
      marks: formData.marks
    },
    title: formData.title ?? defaultTitle,
  }
}

export const formDataToChartTarget = (formData: Omit<TargetExtendedConfModel, 'viewCode' | 'metricCode'>, defaultTitle: string): TargetExtendedConfModel => _.merge({
    ...formData,
    type: WidgetTypes.TARGET,
    extraConf: {
      displayType: formData.displayType,
      printPrevisions: formData.printPrevisions,
      ignoreSeasonality: formData.ignoreSeasonality,
    },
    title: formData.title ?? defaultTitle,
  },
)

export const updateDateSlicer = <T extends SlicerDtoDetailTypes, >(slicers: T[], granularity: Granularity | null): T[] => {
  const dateSlicer = slicers.find(slicer => slicer.type === "date")
  if (dateSlicer) {
    return slicers.map(slicer => {
      if (slicer.type === "date") {
        return {
          ...slicer,
          type: "date",
          granularity: granularity ?? undefined,
        }
      }
      return {
        ...slicer,
        type: "dimension",
        dimensionCode: slicer.dimensionCode,
      }
    })
  }
  return slicers
}

export const convertConfSlicerToSlicer = (confSlicers: ConfSlicer[]): SlicerDtoDetailTypes[] => confSlicers.map(confSlicer => omit(confSlicer, "isDefault") as SlicerDtoDetailTypes)

export const createSlicers = (slicers: DimensionCacheElement[], granularity: Granularity | null) => slicers.map(s => {
  if (s.code === "date") {
    return {
      type: "date",
      granularity,
      isDefault: s.isDefault,
    } as ConfSlicer
  } else {
    return {
      type: "dimension",
      dimensionCode: s.code,
      isDefault: s.isDefault,
    } as ConfSlicer
  }
})

export const sortSlicersAxisFirst = (slicers: DimensionCacheElement[]) => Object.assign([] as DimensionCacheElement[], slicers).sort((a, b) => {
  if (a.isAxis) {
    return -1
  } else if (b.isAxis) {
    return 1
  }
  return 0
})

const updateMetricObject = (metrics: MetricDtoDetail[], filters: ConfMetricFilterTypes[] | undefined, isAxisOptionAllowed: boolean, viewsWithMetrics: MetaModelView[], isMultiView: boolean, metaModel: MetaModel, availableDimensions: ConsolidatedDimension[], languageResolver: LanguageResolver) => metrics.map((m) => {
  return {
    ...m,
    metricDef: undefined,
    extraConf: {
      ...m.extraConf,
      isDisplayedOnSecondaryAxis: isAxisOptionAllowed ? m.extraConf?.isDisplayedOnSecondaryAxis : false,
    },
    having: filters?.filter(filter => !isFilterEmpty(filter)).find(filter => {
      const metricDef = getMetricDef(metaModel, m)
      const metricAlias = metricDef ? buildMetricLabel({
        ...m,
        additionalFilters: converterFilterToConfModel(m.additionalFilters, availableDimensions),
        metricDef,
      }, languageResolver) : undefined
      const metricLabel = m && viewsWithMetrics && metricAlias ? getMetricLabel(viewsWithMetrics, {
        metricCode: `${m.metricCode}`,
        metricAlias,
        viewCode: m.viewCode,
      }, isMultiView) : undefined
      return filter.reference.alias === metricLabel
    })?.predicate,
    extraConfDefaultProperties: undefined,
  }
})

export const formatConfDimensionFilterToDtoDetail = (filters: ConfDimensionFilterTypes[]): DimensionFilterDtoDetail[] => filters.map(filter => ({
  type: FilterType.dimension,
  dimensionCode: filter.reference.code,
  predicate: filter.predicate,
}))

export const formatConfMetricFilterToDtoDetail = (filters: ConfMetricFilterTypes[]): MetricFilterDtoDetail[] => filters.map(filter => ({
  type: FilterType.metric,
  metricCode: filter.reference.code,
  predicate: filter.predicate,
} as MetricFilterDtoDetail))

export const formatConfFilterToDtoDetail = (filters: ConfFilter[]): FilterDtoDetailTypes[] => filters.map(filter => {
  switch (filter.type) {
    case FilterType.dimension:
      return {
        type: FilterType.dimension,
        dimensionCode: filter.reference.code,
        predicate: filter.predicate,
      }
    case FilterType.metric:
      return {
        type: FilterType.metric,
        metricCode: filter.reference.code,
        predicate: filter.predicate,
      }
    default: {
      const exhaustiveCheck = filter
      return exhaustiveCheck
    }
  }
})

export const formatFormDataToChartDto = <T extends GenericExtendedConfModel, >(newData: T, isAxisOptionAllowed: boolean, viewWithMetrics: MetaModelView[], isMultiView: boolean, metaModel: MetaModel, availableDimensions: ConsolidatedDimension[], languageResolver: LanguageResolver, defaultTitle: string): Omit<ChartGenericDtoDetail, "x" | "y" | "h" | "w"> => {
  const formattedFormData = formDataToChartGeneric(newData, defaultTitle)
  return {
    ...omit(formattedFormData, ["metricFilters"]) as Omit<T, "title" | "type" | "metricFilters"> & Pick<ExtendedConfModel, "extraConf" | "type" | "title">,
    slicers: convertConfSlicerToSlicer(updateDateSlicer(formattedFormData.slicers, newData.dateSlicerGranularity)),
    metrics: updateMetricObject(formattedFormData.metrics, formattedFormData.metricFilters, isAxisOptionAllowed, viewWithMetrics, isMultiView, metaModel, availableDimensions, languageResolver),
    filters: formatConfDimensionFilterToDtoDetail(formattedFormData.filters).filter(filter => !isFilterEmpty(filter)),
  }
}

export const formatFormDataToExportDto = (formData: ExportExtendedConfModel, viewWithMetrics: MetaModelView[], isMultiView: boolean, metaModel: MetaModel, defaultTitle: string, availableDimensions: ConsolidatedDimension[], languageResolver: LanguageResolver): ExportBasicDtoForm => {
  return {
    ...omit(formData, ["metricFilters"]),
    type: WidgetTypes.EXPORT,
    // dateSlicerGranularity: formData.dateSlicerGranularity ?? Granularity.DAY,
    displayType: GenericChartTypes.TABLES,
    ignoreMetrics0: Boolean(formData.ignoreMetrics0),
    title: formData.title ?? defaultTitle,
    slicers: updateDateSlicer(formData.slicers, formData.dateSlicerGranularity),
    metrics: updateMetricObject(formData.metrics, formData.metricFilters, false, viewWithMetrics, isMultiView, metaModel, availableDimensions, languageResolver),
    filters: formatConfDimensionFilterToDtoDetail(formData.filters),
  }
}

const IconFeatureNew = ({...props}) => <IconContainer><LightningBoltIcon color={"var(--primary)"} {...props} /></IconContainer>

export const uniqueViewOptions = (metaModel: MetaModel): ViewOption[] => [
  {
    label: <FlexDiv><IconFeatureNew/> {Language.get('configuration-chart-uniqueView-all')}</FlexDiv>,
    value: null,
  },
  ...metaModel.listViews().map(({alias, code, description}) => ({
    label: alias,
    value: code,
    description,
  })),
]

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`


export const convertOrderByToConfOrderBy = (orderBys: OrderBy[]): ConfOrderBy[] => orderBys.map(orderBy => ({
  ...orderBy,
  isDefault: false,
}))

export const useDimensionFilterConverterToConfModel = (
  filters: (DimensionFilterDtoDetail | ConfDimensionFilterTypes)[],
  availableDimensions?: ConsolidatedDimension[]) => useCallback((): ConfDimensionFilterTypes[] => converterFilterToConfModel(
  filters,
  availableDimensions), [availableDimensions, filters])

export const useMetricAliasWithoutViewFormatter = (
  metrics: GenericExtendedConfModel["metrics"],
  viewsWithMetrics: ViewWithMetrics[]) => {
  const languageResolver = useLanguageResolver()

  return useCallback(() => metrics.map((metric) => {

    const metricAlias = buildMetricLabel(metric, languageResolver)

    return {
      ...metric,
      metricAlias: metricAlias ?? metric.metricAlias,
      viewAlias: viewsWithMetrics.find(view => view.metrics.find(m => m.code === metric.metricCode))?.alias ?? "",
    }
  }), [languageResolver, metrics, viewsWithMetrics])
}

export const getAvailableAdditionalDetails = (type: WidgetTypes, displayType?: GenericChartTypes) => {
  switch(type) {
    case WidgetTypes.TARGET:
      return [TargetAdditionalDetails.printPrevisions, TargetAdditionalDetails.ignoreSeasonality]
    case WidgetTypes.GENERIC:
      switch(displayType) {
        case GenericChartTypes.AREA:
        case GenericChartTypes.LINE:
        case GenericChartTypes.BARS:
        case GenericChartTypes.PIE:
        case GenericChartTypes.SCATTER:
          return [GenericAdditionalDetails.displayLabels]
        case GenericChartTypes.TABLES:
          return [GenericAdditionalDetails.ignoreMetrics0, GenericAdditionalDetails.asPercentage]
        default:
          return []
      }
    default:
      return []
  }
}