/* eslint-disable max-lines */
import * as WorkspaceSchemas from "schemas/workspace"
import {NormalizedWorkspaceDetail, NormalizedWorkspaceModels} from "schemas/workspace"
import {captureError} from "services/SentryService"
import {
  addDashboard,
  addMenu,
  cloneWorkspaceDashboard,
  deleteDashboard,
  deleteMenu,
  editMenu,
  getWorkspace,
  getWorkspaces,
  updateDashboardTitleAndPosition,
} from "services/NavigationService"
import {consolidateRawDashboardPeriod, setEntities} from "redux/models/utils.model"
import {returnOrExecBatch} from 'commons/batch'
import {getCurrentEnvironment} from "redux/environment.selector"
import {getDashboardId, getMenu, getWorkspaceDashboard} from "redux/currentDashboard.selector"
import {getPeriods} from "redux/period.selector"
import {findWorkspaceDashboardInfosWith, getCurrentWorkspaceId} from "redux/workspace.selector"
import {omit} from "lodash"
import store from "redux/store"
import {createModel, RematchRootState} from "@rematch/core"
import RootModel from "redux/models/index"
import {DashboardDto, MenuDto} from "components/workspace/WorkspaceBridge.SiderContainer"
import {ChartDtoDetailTypes, RawChartTypes} from "types/charts"
import {buildWorkspaceUri} from "components/workspace/Workspace.utils"
import {NormalizedSchema} from "normalizr"
import {PeriodTypes, predefinedPeriod} from "@biron-data/period-resolver"
import {RawWorkspaceDashboardDto, WorkspaceDashboardDto} from "types/dashboards"
import {NormalizedDashboardTypes} from "schemas/dashboard"
import {createWorkspace, deleteWorkspace, updateWorkspace} from "../../services/WorkspaceService";

export interface Homepage {
  id: number;
  relativeId: number;
  title: string;
  uri: string
  period: PeriodTypes
  filters: any[];
  charts: ChartDtoDetailTypes[];
  filtersLocked: boolean
}

export interface RawHomepage {
  id: number
  relativeId: number
  title: string
  period: PeriodTypes
  filters: any[]
  charts: RawChartTypes[]
}

export interface RawMenu {
  id: number
  name: string
  dashboards: RawWorkspaceDashboardDto[]
}

export interface RawExpandedWorkspace extends SummarizedWorkspace {
  homepage: RawHomepage;
  menus: RawMenu[];
}

export interface ExpandedWorkspace extends SummarizedWorkspace {
  homepage: Homepage;
  menus: Menu[];
}

export interface Menu {
  id: number
  name: string
  dashboards: WorkspaceDashboardDto[]
}

export interface SummarizedWorkspace {
  id: number,
  uri: string,
  name: string,
  environment: {
    id: number,
    name: string
  }
}

export interface WorkspaceDtoForm {
  uri?: string,
  name: string,
}

export type StringIndex<T> = Record<string, T>

interface NormalizedWorkspace extends NormalizedModel {
  initialized: boolean,
  loading: boolean,
  currentWorkspace?: NormalizedWorkspaceDetail
  entities: NormalizedWorkspaceModels
}

const currentWorkspaceInitialEntities = {
  menus: {},
  dashboards: {},
  charts: {},
}

export interface NormalizedModel {
  entities: Record<string, any>
}

const initialState: NormalizedWorkspace = {
  initialized: false,
  loading: false,
  currentWorkspace: undefined, // a detailed workspace

  entities: {
    workspaces: {}, // a collection of resumed workspace
    ...currentWorkspaceInitialEntities,
  },
}

const getNormalizedWorkspace = (currentWorkspaceRaw: RawExpandedWorkspace, periods: PeriodTypes[]): NormalizedSchema<NormalizedWorkspaceModels, number> => WorkspaceSchemas.normalizeWorkspaceDetail({
  ...currentWorkspaceRaw,
  homepage: consolidateRawDashboardPeriod(currentWorkspaceRaw.homepage, periods),
  menus: currentWorkspaceRaw.menus.map(menu => ({
    ...menu,
    dashboards: menu.dashboards.map(dashboard => consolidateRawDashboardPeriod(dashboard, periods)),
  })),
})

export default createModel<RootModel>()({
  state: initialState,
  reducers: {
    setEntities,
    setInitialized(state) {
      return {
        ...state,
        initialized: true,
      }
    },
    setLoading(state: NormalizedWorkspace, loading: boolean) {
      return {
        ...state,
        loading,
      }
    },
    setCurrentRaw(state: NormalizedWorkspace, normalized: NormalizedSchema<NormalizedWorkspaceModels, number>) {
      const currentWorkspace = Object.values(normalized.entities.workspaces)[0]
      return {
        ...state,
        currentWorkspace,
        entities: {
          ...state.entities,
          ...currentWorkspaceInitialEntities,
          ...omit(normalized.entities, ['workspaces']),
        },
      }
    },
  },

  effects: (dispatch) => {
    const reloadWorkspace = async (state: RematchRootState<RootModel>) => {
      const rawWorkspace = await getWorkspace(getCurrentWorkspaceId(state))
      const environment = getCurrentEnvironment(state)
      if (environment.id) {
        const periods = await getPeriods(state)
        dispatch.workspace.setCurrentRaw(getNormalizedWorkspace(rawWorkspace, periods))
      }
      return store.getState()
    }

    return {
      async init({forBatch = false}: { forBatch?: boolean } = {}, state): Promise<any> {
        if (state.workspace.initialized) {
          return null
        }
        const rawWorkspaces = await getWorkspaces()
        const normalizedWorkspaces = WorkspaceSchemas.normalizeWorkspaceResumes(rawWorkspaces)
        return returnOrExecBatch(forBatch,
          () => dispatch.workspace.setEntities(normalizedWorkspaces.entities),
          () => dispatch.workspace.setInitialized(),
        )
      },
      async setCurrentFromUri({uri, environmentId}: { uri?: string, environmentId?: number }, state): Promise<string | undefined> {
        const workspaces = Object.values(state.workspace.entities.workspaces)
        dispatch.workspace.setLoading(true)

        const workspace = (uri && workspaces.find(w => w.uri === uri)) || (uri === "personal" && workspaces.find(w => w.environment.id === environmentId))
        if (workspace) {
          const {id, environment} = workspace
          const {payload: periods} = await dispatch.periods.loadPeriods({environment: {id: environment.id}})
          dispatch({type: 'workspace/cleanStates'})
          dispatch({type: 'personalDashboard/cleanStates'})
          await dispatch.environment.updateCurrentClient({
            environment,
          })
          await dispatch.appEnvironment.loadContext({
            environmentId: environment.id,
            workspaceId: id,
          })
          await dispatch.personalDashboards.loadPersonalDashboards({
            environmentId: environment.id,
          })

          const rawWorkspace = await getWorkspace(id)
          dispatch.workspace.setCurrentRaw(getNormalizedWorkspace(rawWorkspace, periods))

          return new Promise((resolve) => {
            return resolve(undefined)
          })
        } else if (workspaces.length > 0) {
          if (uri) {
            // eslint-disable-next-line no-console
            console.warn(`workspace [${uri}] not found -> redirect to first workspace`)
          }
          return new Promise((resolve) => {
            return resolve(buildWorkspaceUri(workspaces[0]))
          })
        } else {
          return new Promise((resolve) => {
            return resolve('/nav/noAuthorization')
          })
        }
      },
      async addMenu(value: MenuDto, state): Promise<void> {
        await addMenu(value, getCurrentWorkspaceId(state))
        await reloadWorkspace(state)
      },

      async editMenu({value, menuId}: { value: MenuDto, menuId: number | string }, state): Promise<void> {
        await editMenu({workspaceId: getCurrentWorkspaceId(state), ...value}, menuId)
        await reloadWorkspace(state)
      },

      async deleteMenu(id: number | string, state): Promise<void> {
        const currentDashboard = getWorkspaceDashboard(state)
        const currentMenu = getMenu(state)
        if (currentDashboard && currentMenu && currentMenu.id === id && currentMenu.dashboards.includes(currentDashboard.id)) {
          // @ts-ignore
          dispatch.currentDashboard.setFromUri({})
        }
        await deleteMenu(id)
        await reloadWorkspace(state)
      },

      async addDashboard(value: DashboardDto, state): Promise<NormalizedDashboardTypes> {
        const createDashboardValue = {
          ...value,
          period: predefinedPeriod.LAST_7_DAYS,
        }

        const newDashboard = await addDashboard(createDashboardValue, value.parentMenu)
        const newState = (await reloadWorkspace(state))

        return newState.workspace.entities.dashboards[newDashboard.id]
      },
      async editDashboard(
        {
          dashboardId,
          parentMenu: newMenuId,
          positionDashboard: position,
          title,
        }: DashboardDto & { dashboardId: number },
        state): Promise<void> {
        const dashboardInfos = findWorkspaceDashboardInfosWith(state, 'id', dashboardId)

        const {dashboard, menu} = dashboardInfos ?? {}

        if (!dashboard && !title) {
          captureError("Could not edit dashboard, because the dashboard could not be found and the new value is undefined", {})
          return
        }

        const data = {
          ...omit(dashboard, ['relativeId', 'charts', 'slicers', 'filters']) as Omit<WorkspaceDashboardDto, 'relativeId' | 'charts' | 'slicers' | 'filters'>,
          ...{
            title: title as string,
          },
        }

        const uriParams = {
          position: Number(position),
          menuId: menu?.id,
          newMenuId,
        }

        await updateDashboardTitleAndPosition(dashboardId, data, uriParams)
        await reloadWorkspace(state)
      },
      async cloneDashboard({dashboardId, value}: { dashboardId: number, value: DashboardDto }, state): Promise<NormalizedDashboardTypes> {
        const newDashboard: any = await cloneWorkspaceDashboard({originDashboard: dashboardId, ...value})
        const newState = (await reloadWorkspace(state))

        return newState.workspace.entities.dashboards[newDashboard.id]
      },
      async deleteDashboard({dashboardId, menuId}: { dashboardId: number, menuId: number }, state) {

        if (dashboardId === getDashboardId(state)) {
          // @ts-ignore
          dispatch.currentDashboard.setFromUri({})
        }
        await deleteDashboard({
          dashboardId,
          menuId,
        })
        await reloadWorkspace(state)
      },
      async createWorkspace({dto, environmentId}: { dto: WorkspaceDtoForm, environmentId: number }, state) {
        const newWorkspace = await createWorkspace(dto, environmentId)

        const newWorkspaceWithEnvironment = {
          ...newWorkspace,
          environment: {
            id: state.environment.currentClientId,
          }
        }

        dispatch.workspace.setEntities({
          workspaces: {
            [newWorkspace.id]: newWorkspaceWithEnvironment,
          },
        })

        return newWorkspace
      },
      async updateWorkspace({dto, id}: { dto: WorkspaceDtoForm, id: number }, state) {
        const newWorkspace = await updateWorkspace(dto, id)
        dispatch.workspace.setEntities({
          workspaces: {
            [newWorkspace.id]: newWorkspace,
          },
        })
      },
      async deleteWorkspace({id, environmentId, onRedirect}: {
        id: number,
        environmentId: number,
        onRedirect: (workspace: SummarizedWorkspace) => void
      }, state) {
        await deleteWorkspace(id)

        const newState = state.workspace.entities.workspaces

        if (id === state.workspace.currentWorkspace?.id) {
          const availableWorkspace = Object.values(newState).find(workspace => workspace.environment.id === environmentId)
          if (availableWorkspace) {
            onRedirect(availableWorkspace)
          }
        }

        Reflect.deleteProperty(newState, id)

        dispatch.workspace.setEntities({
          workspaces: newState,
        })
      },
    }
  },
})

